import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import toastr from 'toastr'

Vue.config.productionTip = false

import i18n from './lang/i18n.js';

import moment from 'moment';
import 'moment-timezone';

moment.locale('nl');

const ISO_8601 = /(\d{4}-\d{2}-\d{2})T(\d{2}:\d{2}:\d{2}(.\d{3})?)(Z)?/

function convertDateStringsToDateObjects(obj) {
    for (var property in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, property)) {
            if (typeof obj[property] === 'object') {
                convertDateStringsToDateObjects(obj[property])
            } else {
                if (ISO_8601.test(obj[property])) {
                    obj[property] = new Date(Date.parse(obj[property]))
                }
            }
        }
    }
    return obj
}

axios.interceptors.response.use(response => {
    response.data = convertDateStringsToDateObjects(response.data)
    return response
}, error => Promise.reject(error))

import { AUTH_LOGOUT } from './store/actions/authactions';

axios.interceptors.response.use(response => response,
    error => {
        if (error.response) {
            if (error.response.status == 401 || error.response.status == 403) {
                store.dispatch(`auth/${AUTH_LOGOUT}`)
                    .then(() => {
                        router.push({ name: 'login' });
                    });
            } else if (error.response.status == 409) {
                toastr.error(i18n.t('error.deleteRecordConflict'), '');
            }
        }

        return Promise.reject(error);
    }
);

const user = store.getters['auth/user'];
if (user.token) {
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + user.token;
}
axios.defaults.baseURL = process.env.VUE_APP_API_URL

// https://www.npmjs.com/package/openapi-client-axios
import OpenAPIClientAxios from 'openapi-client-axios';
const api = new OpenAPIClientAxios({ definition: process.env.VUE_APP_OPEN_API_URL });
api.init();

Vue.prototype.$http = axios;
Vue.prototype.$api = api;

import nl from 'vee-validate/dist/locale/nl';
import VeeValidate, { Validator } from 'vee-validate';
const validationConfig = {
    aria: true,
    classNames: {
        valid: 'is-valid',
        invalid: 'is-invalid'
    },
    classes: true,
    // delay: 0,
    dictionary: {
        nl: nl
    },
    // errorBagName: 'errors', // change if property conflicts
    events: 'input|blur',
    // fieldsBagName: 'fields',
    i18n: i18n, // the vue-i18n plugin instance
    i18nRootKey: 'validations', // the nested key under which the validation messages will be located
    // inject: true,
    // locale: 'nl',
    // strict: true,
    // validity: false,
};

// password strength
Validator.extend('verify_password', {
    getMessage: () => i18n.t('passwordRequirements'),
    validate: value => {
        var strongRegex = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{6,})');
        return strongRegex.test(value);
    }
});

Vue.use(VeeValidate, validationConfig);

import './displayformat/index.js';
import './components/common/index.js';

import { default as Vuedals, Component as Vuedal } from 'vuedals';
Vue.use(Vuedals);
Vue.component('vuedal', Vuedal);

import { library } from '@fortawesome/fontawesome-svg-core'
import { faEdit, faTrash, faSearch, faTimes, faAngleDown, faList, faEye, faEyeSlash, faPlusSquare, faSave, faDownload, faPlus, faFolderPlus, faObjectGroup, faCheck, faHistory, faLock, faPrint, faFileExcel, faSync } from '@fortawesome/free-solid-svg-icons'

library.add(faEdit, faTrash, faSearch, faTimes, faAngleDown, faList, faEye, faEyeSlash, faPlusSquare, faSave, faDownload, faPlus, faFolderPlus, faObjectGroup, faCheck, faHistory, faLock, faPrint, faFileExcel, faSync)
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
Vue.component('font-awesome-icon', FontAwesomeIcon);

import AllIosIcon from 'vue-ionicons/dist/ionicons-ios.js';
Vue.use(AllIosIcon);

new Vue({
    router,
    store,
    i18n,
    render: h => h(App)
}).$mount('#app')