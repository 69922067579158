<template>
    <div class="d-flex pos-relative align-items-center" :class="heightclass">
        <div class="sk-double-bounce">
            <div class="sk-child sk-double-bounce1 bg-gray-800"></div>
            <div class="sk-child sk-double-bounce2 bg-gray-800"></div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Spinner',
    props: {
        heightclass: {
            type: String,
            default: 'ht-150'
        }
    },
    data() {
        return {
        };
    },
};
</script>