import axios from 'axios';
import querystring from 'qs';
import {
    AUTH_REQUEST, AUTH_ERROR, AUTH_SUCCESS, AUTH_LOGOUT, SET_ROLE,
    AUTH_REQUEST_IMPERSONATE, AUTH_SUCCESS_IMPERSONATE, AUTH_ADMINUSER, AUTH_EXACHANGEUSER

} from '../actions/authactions';
import * as cookie from 'tiny-cookie';

const USERCOOKIE = 'usersettings';
const USERADMINCOOKIE = 'useradminsettings';
let user = null;
const userCookieValue = cookie.get(USERCOOKIE);
if (userCookieValue)
    user = JSON.parse(userCookieValue);
else
    user = {
        token: null,
        role: null,
        username: null,
    };

let useradmin = null;
const userAdminCookieValue = cookie.get(USERADMINCOOKIE);
if (userAdminCookieValue)
    useradmin = JSON.parse(userAdminCookieValue);
else
    useradmin = {
        token: null,
        role: null,
        username: null,

    };
const state = {
    user: user,
    useradmin: useradmin,
    testenvironment: true,
};

const getters = {
    isAuthenticated: state => !!state.user.token,
    authStatus: state => state.status,
    user: state => state.user,
    isAdministrator: state => state.user.role === 'Administrator',
    isCustomerAdmin: state => state.user.role === 'CustomerAdmin',
    isCustomerUser: state => state.user.role === 'CustomerUser',
    testenvironment: state => state.testenvironment,
    useradmin: state => state.useradmin,
};

const actions = {
    [AUTH_REQUEST]: ({ commit }, user) => {
        return new Promise((resolve, reject) => {
            commit(AUTH_REQUEST);
            const data = { grant_type: 'password', username: user.username, password: user.password, scope: 'roles' };
            axios.post('api/connect/token', querystring.stringify(data))
                .then(resp => {
                    commit(AUTH_SUCCESS, resp);
                    axios.get( 'api/applicationuser/me')
                        .then(resp2 => {
                            commit(SET_ROLE, resp2);
                            resolve(resp2);
                        })
                        .catch(err => {
                            commit(AUTH_ERROR, err);
                            reject(err);
                        });
                })
                .catch(err => {
                    console.log(err);
                    commit(AUTH_ERROR, err);
                    reject(err);
                });
        });
    },
    [AUTH_LOGOUT]: ({ commit }) => {
        return new Promise((resolve) => {
            commit(AUTH_LOGOUT);
            resolve();
        });
    },
    [SET_ROLE]: ({ commit }, role) => {
        commit(SET_ROLE, role);
    },
    [AUTH_REQUEST_IMPERSONATE]: ({ commit }, user) => {
        console.log(AUTH_ADMINUSER);
        commit(AUTH_ADMINUSER);
        return new Promise((resolve, reject) => {
            commit(AUTH_REQUEST_IMPERSONATE);
            axios.post('api/connect/token', querystring.stringify({ grant_type: 'password', username: user.username, password: user.password, scope: 'roles', loginHint: 'impersonate' }))
                .then(resp => {
                    commit(AUTH_SUCCESS_IMPERSONATE, resp);
                    resolve(resp);
                })
                .catch(err => {
                    commit(AUTH_ERROR, err);
                    reject(err);
                });
        });
    },
    [AUTH_EXACHANGEUSER]: ({ commit }) => {
        //return this.AUTH_REQUEST_IMPERSONATE({ username: state.useradmin.username, password: state.useradmin.username });       
        commit(AUTH_EXACHANGEUSER);
    }
};

const mutations = {
    [AUTH_REQUEST]: (state) => {
        state.status = 'loading';
    },
    [AUTH_REQUEST_IMPERSONATE]: (state) => {
        state.status = 'loading';
    },

    [SET_ROLE]: (state, resp) => {
        state.user.role = resp.role;
        state.user.username = resp.username;
        cookie.set(USERCOOKIE, JSON.stringify(state.user), Infinity);
    },
    [AUTH_SUCCESS]: (state, resp) => {
        state.status = 'success';
        state.user.token = resp.data.access_token;
        cookie.set(USERCOOKIE, JSON.stringify(state.user), Infinity);
        // Here set the header of your ajax library to the token value.
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + resp.data.access_token;
    },
    [AUTH_SUCCESS_IMPERSONATE]: (state, resp) => {
        state.status = 'success';
        state.user.token = resp.data.access_token;
        state.user.role = resp.data.role;
        state.user.loginHint = 'impersonate';
        cookie.set(USERCOOKIE, JSON.stringify(state.user), Infinity);
        // Here set the header of your ajax library to the token value.
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + resp.data.access_token;

    },
    [AUTH_ERROR]: (state) => {
        state.status = 'error';
        state.user = {
            token: null
        };
        cookie.remove(USERCOOKIE);
    },
    [AUTH_LOGOUT]: (state) => {
        state.user = {
            token: null
        };
        cookie.remove(USERCOOKIE);
        // remove the axios default header
        delete axios.defaults.headers.common['Authorization'];
    },
    [AUTH_ADMINUSER]: (state) => {
        cookie.set(USERADMINCOOKIE, JSON.stringify(state.user), Infinity);
        state.useradmin.username = state.user.username;
        state.useradmin.role = state.user.role;
        state.useradmin.token = state.user.token;
    },
    [AUTH_EXACHANGEUSER]: (state) => {
        delete axios.defaults.headers.common['Authorization'];
        state.user.username = state.useradmin.username;
        state.user.role = state.useradmin.role;
        state.user.token = state.useradmin.token;
        state.user.loginHint = '';
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + state.useradmin.token;
        cookie.set(USERCOOKIE, JSON.stringify(state.user), Infinity);
        cookie.remove(USERADMINCOOKIE);
        state.useradmin.username = null;
        state.useradmin.role = null;
        state.useradmin.token = null;

    },
};
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};