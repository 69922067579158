import Vue from 'vue'
import Vuex from 'vuex'
import auth from './modules/auth';
import userlist from './modules/userlist';
import customerlist from './modules/customerlist';
import customeruserlist from './modules/customeruserlist';
Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        auth,
        userlist,
        customerlist,
        customeruserlist
    },
    strict: !process.env.PRODUCTION,

});
