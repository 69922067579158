<template>
    <vue-datepicker-local :value="value"
                          :input-class="'form-control form-control-sm '+inputclass"
                          :format="$t('format.date')"
                          @input="inputChanged"
                          :local="local"
                          :disabled="disabled"
                          :clearable="clearable"></vue-datepicker-local>
</template>

<script>
    import VueDatepickerLocal from 'vue-datepicker-local';

    export default {
        components: {
            'vue-datepicker-local': VueDatepickerLocal
        },
        props: {
            value: {
                type: [Date, String],
                default: null
            },
            disabled: {
                type: Boolean,
                default: false
            },
            typeable: {
                type: Boolean,
                default: true
            },
            inputclass: {
                type: String,
                default: ''
            },
            clearable: {
                type: Boolean,
                default: true
            }
        },
        name: 'DatePicker',
        data() {
            return {
                local: {
                    dow: 1, // Monday is the first day of the week
                    hourTip: '', // tip of select hour
                    minuteTip: '', // tip of select minute
                    secondTip: '', // tip of select second
                    yearSuffix: '', // format of head
                    monthsHead: [
                        this.$t('periods.short.january'),
                        this.$t('periods.short.february'),
                        this.$t('periods.short.march'),
                        this.$t('periods.short.april'),
                        this.$t('periods.short.may'),
                        this.$t('periods.short.june'),
                        this.$t('periods.short.july'),
                        this.$t('periods.short.august'),
                        this.$t('periods.short.september'),
                        this.$t('periods.short.october'),
                        this.$t('periods.short.november'),
                        this.$t('periods.short.december'),
                    ], // months of head
                    months: [
                        this.$t('periods.short.january'),
                        this.$t('periods.short.february'),
                        this.$t('periods.short.march'),
                        this.$t('periods.short.april'),
                        this.$t('periods.short.may'),
                        this.$t('periods.short.june'),
                        this.$t('periods.short.july'),
                        this.$t('periods.short.august'),
                        this.$t('periods.short.september'),
                        this.$t('periods.short.october'),
                        this.$t('periods.short.november'),
                        this.$t('periods.short.december'),
                    ], // months of panel
                    weeks: 'Ma_Di_Wo_Do_Vr_Za_Zo'.split('_'), // weeks
                    cancelTip: '', // default text for cancel button
                    submitTip: '' // default text for submit button
                }
            };
        },
        methods: {
            inputChanged(val) {

                this.$emit('input', val);
            }
        }
    };

</script>

<style lang="scss">
    .datepicker {
        &:before {
            background: none;
        }

        & > input {
            border: 1px solid #ced4da;

            &[readonly] {
                background-color: #fff;
                opacity: 1;
            }
        }
    }
</style>