import Vue from 'vue';
import VueI18n from 'vue-i18n';
import axios from 'axios';

Vue.use(VueI18n);

const i18n = new VueI18n({
    locale: 'nl', // set locale
    fallbackLocale: 'nl',
    messages: {} // set locale messages
});
export async function fetch() {
    await axios.get(process.env.VUE_APP_API_URL + 'api/user/translation')
        .then(resp => {
            i18n.setLocaleMessage('nl', resp.data);
            console.log('2');
        });
}

export default i18n;