<template>
    <div v-if="progress" class="modal fade show bg-black-6" style="display: block;">
        <div class="modal-dialog modal-sm wd-500" role="document">
            <div class="modal-content bd-0 tx-14">
                <div class="modal-body pd-20">
                    <div v-if="progress > 0" class="progress mg-b-20 mg-t-5 bd">
                        <div class="progress-bar progress-bar-striped progress-bar-lg" :style="'width:'+Math.round(progress)+'%'"
                             role="progressbar" :aria-valuenow="progress" aria-valuemin="0" aria-valuemax="100">
                            {{progress | int}}%
                        </div>
                    </div>
                    <spinner v-else :heightClass="''"></spinner>
                    <div v-if="message">
                        {{message}}
                    </div>
                </div>
            </div>
        </div><!-- modal-dialog -->
    </div>
</template>

<script>
    import uniqid from 'uniqid';
    import * as signalR from '@aspnet/signalr';

    export default {
        name: 'ProgressDialog',
        data() {
            return {
                connection: null,
                progress: null,
                guid: null,
                message: null
            };
        },
        methods: {
            start() {
                this.guid = uniqid();
                let url = process.env.VUE_APP_API_URL + 'api/progress';

                this.connection = new signalR.HubConnectionBuilder().withUrl(url).build();
                this.connection.on('updateProgressBar_' + this.guid, (perc) => {
                    this.progress = perc;
                });
                this.connection.on('updateProgressMessage_' + this.guid, (message) => {
                    this.message = message;
                });
                return this.connection.start();
            },
            stop() {
                this.connection.stop();
                this.progress = null;
                this.guid = null;
            }
        }
    };
</script>