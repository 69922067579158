<template>
    <div>
        <paging v-if="totalPages>1" :current-page="page" :total-pages="totalPages" @page-changed="goToPage" :marginTop="false"><slot/></paging>        
        <slot v-else />
        <inner-image-zoom :src="url" :zoomSrc="url" zoomType="hover" class="innerimagezoom" />
    </div>
</template>
<script>
    import InnerImageZoom from 'vue-inner-image-zoom';
    import 'vue-inner-image-zoom/lib/vue-inner-image-zoom.css';

    
    export default {
        name: 'ImageViewer',
        components: {
            'inner-image-zoom': InnerImageZoom
        },
        props: {
            images: [Number, String, Array],
            inputclass: {
                type: String,
                default: ''
            }
        },
        data() {
            return {
                page: 0
            };
        },
        mounted() {
            this.goToPage(this.totalPages-1);
        },
        watch: {
            images() {
                this.goToPage(this.totalPages-1);
            }
        },
        computed: {
            url() {
                return 'data:image/jpeg;base64,' + this.images[this.page];
            },
            totalPages() {
                return this.images.length;
            }
        },
        methods: {
            goToPage(val) {
                this.page = val;
            }
        }
    }

</script>
<style scoped>
    .image-responsive {
        width: 100%;
        height: auto;
    }
    .innerimagezoom {
        border: 1px solid rgba(0, 0, 0, 0.125);
        margin-top:15px;
    }
</style>