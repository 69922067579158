<template>
    <select2 :options="options" :value="value" 
             @change="$emit('input', $event?parseInt($event):null)"
             :class="inputclass" :settings="{width:'100%',allowClear:emptyname != null,placeholder:emptyname}"
             @select="$emit('select', $event)">
    </select2>
</template>

<script>
import Select2 from 'v-select2-component';

export default {
    components: {
        'select2': Select2
    },
    name: 'SelectEnumType',
    props: {
        value: [Number, String],
        inputclass: {
            type: String,
            default: ''
        },
        emptyname: {
            type: String,
            default: null
        },
        includeInactive: {
            type: Boolean,
            default: false
        },
        enumtype: {
            type: String,
            default: null
        },
        url: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            list: []
        };
    },
    computed: {
        options() {
            return this.list.map(x => {
                return { id: x.id, text: this.$t('enums.' + this.enumtype + '.' + x.name) };
            });
        },
    },
    created() {
        this.fetch();
    },
    methods: {
        fetch() {
            let requesturl = this.url; 
            if (this.enumtype != null)
                requesturl = this.url + '?type=' + this.enumtype;
            this.$http.get(requesturl)
                .then(resp => this.list = resp.data);
        },
    }
};
</script>