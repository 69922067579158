<template>
    <div>
        <div class="br-logo">
            <router-link to="/" :exact="true">
                <img src="../assets/logo.png" />
            </router-link>
        </div>
        <div class="br-sideleft overflow-y-auto">
            <label class="sidebar-label pd-x-15 mg-t-15">{{ $t('title.navigation') }}</label>
            <div class="br-sideleft-menu">
                <router-link to="/" :exact="true" class="br-menu-link " active-class="active show-sub">
                    <div class="br-menu-item">
                        <ios-home-icon class="tx-22" />
                        <span class="menu-item-label">{{ $t('title.home') }}</span>
                    </div>
                </router-link><!-- br-menu-link -->
                <router-link   :to="{name:'customers'}" :exact="true" class="br-menu-link " active-class="active show-sub">
                    <div class="br-menu-item">
                        <ios-list-icon class="tx-22" />
                        <span class="menu-item-label">{{ $t('title.customers') }}</span>
                    </div>
                </router-link><!-- br-menu-link -->
                
                <a href="javascript:;" class="br-menu-link" @click="navigate('settings')" :class="{'active show-sub': subIsActive($t('path.settings'))}">
                    <div class="br-menu-item">
                        <ios-settings-icon class="menu-item-icon tx-22"></ios-settings-icon>
                        <span class="menu-item-label">{{ $t('title.settings') }}</span>
                        <!--<i class="menu-item-arrow fa fa-angle-down"></i>-->
                        <font-awesome-icon icon="angle-down" class="menu-item-arrow" />
                    </div>
                </a>

                <ul v-if="isAdministrator" class="br-menu-sub nav flex-column" :class="{'displayblock': paths.includes('settings')>0}">
                    <li class="nav-item"><router-link :to="{name:'users'}" active-class="active" class="nav-link">{{ $t('title.administrator') }}</router-link></li>
                    
                </ul>
                <a href="#" @click.prevent="logout" class="br-menu-link">
                    <div class="br-menu-item">
                        <ios-power-icon class="tx-22" />
                        <span class="menu-item-label">{{ $t('actions.logout') }}</span>
                    </div><!-- menu-item -->
                </a>
            </div><!-- br-sideleft-menu -->
        </div><!-- br-sideleft -->
    </div>
</template>

<script>
    import { AUTH_LOGOUT } from '@/store/actions/authactions';
    import { mapActions, mapGetters } from 'vuex';

    export default {
        name: 'NavMenu',
        data() {
            return { paths: [] };
        },
        computed: {
            ...mapGetters('auth', ['isAdministrator','isCustomerAdmin']),
        },
        mounted() {
            this.fetch();
        },
        methods: {
            fetch() {
                
            },
            async logout() {
                await this.doLogout();
                this.$router.push({ name: 'login' });
            },
            subIsActive(input) {
                const paths = Array.isArray(input) ? input : [input];
                return paths.some(path => {
                    return this.$route.path.indexOf(path) === 0; // current path starts with this path string
                });
            },
            navigate(route) {
                if (this.paths.includes(route)) {
                    for (var i = 0; i < this.paths.length; i++) {
                        if (this.paths[i] === route) {
                            this.paths.splice(i, 1);
                        }
                    }
                }
                else
                    this.paths.push(route);

            },
            ...mapActions('auth', {
                doLogout: AUTH_LOGOUT
            })
        }
    };
</script>
<style type="text/css">
    .displayblock {
        display: block !important;
    }
</style>