import Vue from 'vue';


import VueAutonumeric from 'vue-autonumeric';

import Spinner from './/spinner.vue';
import Search from './inputs/search.vue';
import Paging from './/paging.vue';
import DatePicker from './inputs/datepicker.vue';
import InputToggle from './inputs/inputtoggle.vue';
import SelectOptions from './inputs/selectoptions.vue';
import SelectEnumtype from './inputs/selectenumtype.vue';
import InputMoney from './inputs/inputmoney.vue';

import ProgressDialog from './inputs/progressdialog.vue';
import ImageViewer from './inputs/imageviewer.vue';

import VueQuillEditor from 'vue-quill-editor';

import QuillEdit from './inputs/quill.vue';

// Global components
Vue.component('vue-autonumeric', VueAutonumeric);
Vue.component('paging', Paging);
Vue.component('search', Search);
Vue.component('spinner', Spinner);
Vue.component('datepicker', DatePicker);
Vue.component('selectoptions', SelectOptions);
Vue.component('inputtoggle', InputToggle);
Vue.component('selectenumtype', SelectEnumtype);
Vue.component('inputmoney', InputMoney);

Vue.component('progressdialog', ProgressDialog);
Vue.component('imageviewer', ImageViewer);
Vue.use(VueQuillEditor);
Vue.component('quill', QuillEdit);


import VuejsDialog from 'vuejs-dialog';
Vue.use(VuejsDialog, {
    html: true,
    loader: false,
    okText: 'Ok',
    cancelText: 'Annuleren',
    animation: '',
});

Vue.directive('focus', {
    inserted: function (el) {
        el.focus();
    }
});