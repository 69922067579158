<template>
    <div class="input-group input-group-sm ml-auto mg-r-20">
        <div class="input-group-addon">
            <span class="input-group-text cursorpointer" @click="currentSearch=''">
                <font-awesome-icon icon="times" class="tx-12 lh-0 op-6"/>
            </span>
        </div>
        <input type="text" class="form-control" id="search" name="search" :placeholder="$t('actions.search')" v-model="currentSearch">
    </div>
</template>

<script>
import debounce from 'lodash.debounce';

export default {
    name: 'Search',
    props: ['search'],
    data() {
        return {
            currentSearch: this.search
        };
    },
    watch: {
        currentSearch: {
            handler: debounce(function() { this.$emit('search', this.currentSearch); }, 500)
        }
    },
};
</script>