<template>
    <div>
        <navmenu v-if="isAuthenticated"></navmenu>
        <!-- ########## START: HEAD PANEL ########## -->
        <div v-if="isAuthenticated" class="br-header">
            <div class="br-header-left">
                <div class="navicon-left d-none d-lg-flex">
                    <a id="btnLeftMenu" href="" @click.prevent="collapseMenu">
                        <ios-menu-icon />
                    </a>
                </div>
                <div class="navicon-left d-flex d-lg-none">
                    <a id="btnLeftMenuMobile" href="" @click.prevent="toggleShowLeft">
                        <ios-menu-icon />
                    </a>
                </div>
            </div>
            <div class="br-header-left">
                <h2 v-if="record.testenvironment === 'True' && !useradmin.username">{{$t('title.testenvironment')}}</h2>&nbsp;
                <button v-if="useradmin.username" class="btn btn-primary tx-11 tx-uppercase pd-y-12 pd-x-25 tx-mont tx-medium" type="button" @click="adminlogin">{{$t('actions.backtoadmin')}}</button>
            </div>
            <!-- br-header-left -->
            <div class="br-header-right">
                <nav class="nav">
                    <div class="dropdown" :class="{ show: showDropdown }">
                        <a id="toggle-dropdown"
                           @click.prevent="toggleDropdown"
                           href=""
                           class="nav-link nav-link-profile"
                           data-toggle="dropdown"
                           :aria-expanded="showDropdown">
                            <span class="logged-name hidden-md-down">
                                {{
                record.fullName
                                }}
                            </span>
                            <img src="https://via.placeholder.com/64x64"
                                 class="wd-32 rounded-circle"
                                 alt="" />
                            <span class="square-10 bg-success"></span>
                        </a>
                        <div class="dropdown-menu dropdown-menu-header wd-200"
                             :class="{ show: showDropdown }"
                             x-placement="bottom-start"
                             style="
                position: absolute;
                transform: translate3d(-18px, 48px, 0px);
                top: 0px;
                left: 0px;
                will-change: transform;
              ">
                            <ul class="list-unstyled user-profile-nav">
                                <li>
                                    <router-link :to="{ name: 'editprofile' }">
                                        <ios-person-icon class="icon" />
                                        {{ $t("title.editprofile") }}
                                    </router-link>
                                </li>
                                <li>
                                    <a href="#" @click="logout">
                                        <ios-power-icon class="icon" />
                                        {{ $t("actions.logout") }}
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <!-- dropdown-menu -->
                    </div>
                    <!-- dropdown -->
                </nav>
            </div>
        </div>
        <!-- br-header -->
        <!-- ########## END: HEAD PANEL ########## -->
        <!-- ########## START: MAIN PANEL ########## -->
        <div :class="{ 'br-mainpanel': isAuthenticated }">
            <router-view></router-view>
        </div>
        <!-- br-mainpanel -->
        <!-- ########## END: MAIN PANEL ########## -->
        <vuedal></vuedal>
    </div>
</template>

<script>
    import axios from 'axios';
    import navmenu from "@/components/NavMenu.vue";
    import { AUTH_LOGOUT, SET_ROLE, AUTH_EXACHANGEUSER } from "@/store/actions/authactions";
    import { mapActions, mapGetters } from "vuex";
    import toastr from "toastr";

    export default {
        components: {
            navmenu,
        },
        name: "App",
        data() {
            return {
                record: { fullName: "-", roles: [] },
                showDropdown: false,
            };
        },
        created() {
            this.initOpenApiClient();
        },
        mounted() {
            this.setUserData();
        },
        computed: {
            ...mapGetters("auth", ['isAuthenticated', 'useradmin', 'user']),
        },
        methods: {
            async logout() {
                await this.doLogout();
                this.$router.go({ name: "login" });
            },
            async setUserData() {
                if (!this.isAuthenticated) return;
                const client = await this.$api.getClient();
                client.defaults.headers.common['Authorization'] = axios.defaults.headers.common['Authorization'];
               
                const resp = await client.Me();
                this.record = resp.data;
                this.setRole(resp.data)
            },
            ...mapActions("auth", {
                doLogout: AUTH_LOGOUT,
                setRole: SET_ROLE,
                exchangeuser: AUTH_EXACHANGEUSER
            }),
            convertDateStringsToDateObjects(obj) {
                const ISO_8601 = /(\d{4}-\d{2}-\d{2})T(\d{2}:\d{2}:\d{2}(.\d{3})?)(Z)?/;
                for (var property in obj) {
                    if (Object.prototype.hasOwnProperty.call(obj, property)) {
                        if (typeof obj[property] === "object") {
                            this.convertDateStringsToDateObjects(obj[property]);
                        } else {
                            if (ISO_8601.test(obj[property])) {
                                obj[property] = new Date(Date.parse(obj[property]));
                            }
                        }
                    }
                }
                return obj;
            },
            async initOpenApiClient() {

                const client = await this.$api.getClient();
                client.interceptors.request.use(
                    (response) => {
                        response.data = this.convertDateStringsToDateObjects(response.data);
                        return response;
                    },
                    (error) => Promise.reject(error)
                );

                client.interceptors.response.use(
                    (response) => response,
                    (error) => {
                        console.log(error);
                        if (error.response) {
                            if (error.response.status == 401 || error.response.status == 403) {
                                this.$store.dispatch(`auth/${AUTH_LOGOUT}`).then(() => {
                                    this.$router.push({ name: "login" });
                                });
                            } else if (error.response.status == 409) {
                                toastr.error(this.$t("error.deleteRecordConflict"), "");
                            }
                        }

                        return Promise.reject(error);
                    }
                );
            },
            collapseMenu() {
                const body = document.getElementsByTagName("body")[0];
                const menuLabels = document.getElementsByClassName("menu-item-label");
                const menuArrows = document.getElementsByClassName("menu-item-arrow");
                if (body.classList.contains("collapsed-menu")) {
                    body.classList.remove("collapsed-menu");
                    menuLabels.forEach((x) => {
                        x.classList.remove("op-lg-0-force");
                        x.classList.remove("d-lg-none");
                    });
                    menuArrows.forEach((x) => {
                        x.classList.remove("op-lg-0-force");
                        x.classList.remove("d-lg-none");
                    });
                } else {
                    body.classList.add("collapsed-menu");
                    menuLabels.forEach((x) => {
                        x.classList.add("op-lg-0-force");
                        x.classList.add("d-lg-none");
                    });
                    menuArrows.forEach((x) => {
                        x.classList.add("op-lg-0-force");
                        x.classList.add("d-lg-none");
                    });
                }
            },
            toggleShowLeft() {
                const body = document.getElementsByTagName("body")[0];
                if (body.classList.contains("show-left")) {
                    body.classList.remove("show-left");
                } else {
                    body.classList.add("show-left");
                }
            },
            closeShowLeft() {
                const body = document.getElementsByTagName("body")[0];
                if (body.classList.contains("show-left")) {
                    body.classList.remove("show-left");
                }
            },
            toggleDropdown() {
                this.showDropdown = !this.showDropdown;
            },
            adminlogin: function () {
                let self = this;
                let userdata = { username: this.user.username };
                this.exchangeuser(userdata)
                    .then(() => {
                        this.$nextTick(function () { self.setUserData() });
                    });
            },
        },
    };
</script>
<style lang="scss">
    @import "./css/app.scss";
</style>
