<template>
    <select2 :options="options" :value="value" @change="$emit('input', $event?parseInt($event):null)"
             @select="$emit('select', $event)" :class="inputclass"
             :settings="{
             width:'100%',
             allowClear:emptyname != null,
             placeholder:emptyname,
             tags:tags,
             multiple:multiple,
             }"
             >
    </select2>
</template>

<script>
    import Select2 from 'v-select2-component';

    export default {
        components: {
            'select2': Select2
        },
        name: 'SelectOptions',
        props: {
            value: [Number, String, Array],
            inputclass: {
                type: String,
                default: ''
            },
            emptyname: {
                type: String,
                default: null
            },
            includeInactive: {
                type: Boolean,
                default: false
            },
            action: {
                type: String,
                default: null
            },
            url: {
                type: String,
                default: ''
            },
            tags: {
                type: Boolean,
                default: false
            },
            multiple: {
                type: Boolean,
                default: false
            },
            validate: {
                type: String,
                default: ''
            },
            listdata: {
                type: [Object, Array],
                default: null
            },

        },
        data() {
            return {
                list: []
            };
        },
        computed: {
            options() {
                if(!this.list) return [];
                return this.list.map(x => {
                    return { id: x.id, text: x.name };
                });
            },
        },
        watch: {
            url() {
                this.fetch();
            },
            listdata() {
                if(!this.url)
                    this.list = this.listdata;
            }
        },
        mounted() {
            this.fetch();
        },
        methods: {
            fetch() {

                if (this.url != '') {
                    let requesturl = this.url;
                    this.$http.get(requesturl)
                        .then(resp => {
                            this.list = resp.data;
                            if (this.value != null && typeof this.value === "object" && this.value.length > 0) {
                                this.addOptions();
                            }
                        });
                }
                else {
                    this.list = this.listdata;
                }
            },
            addOptions() {
                if(!this.list) return;
                let items = this.list.map(s => s.id.toString());
                let notexisting = this.value.filter(s => !items.includes(s.toString()));
                if (notexisting.length > 0) {
                    notexisting.forEach(s => {
                        this.list.push({
                            id: s,
                            name: s
                        });
                    });

                }

            }
        }
    };</script>