import Vue from 'vue';
import moment from 'moment';
import 'moment-timezone';
import { fetch } from '../lang/i18n.js';
import i18n from '../lang/i18n.js';
fetch().then(() => {
    const monthNamesShort = [
        '',
        i18n.t('periods.short.january'),
        i18n.t('periods.short.february'),
        i18n.t('periods.short.march'),
        i18n.t('periods.short.april'),
        i18n.t('periods.short.may'),
        i18n.t('periods.short.june'),
        i18n.t('periods.short.july'),
        i18n.t('periods.short.august'),
        i18n.t('periods.short.september'),
        i18n.t('periods.short.october'),
        i18n.t('periods.short.november'),
        i18n.t('periods.short.december'),
    ];


    const months = [
        '',
        i18n.t('periods.long.january'),
        i18n.t('periods.long.february'),
        i18n.t('periods.long.march'),
        i18n.t('periods.long.april'),
        i18n.t('periods.long.may'),
        i18n.t('periods.long.june'),
        i18n.t('periods.long.july'),
        i18n.t('periods.long.august'),
        i18n.t('periods.long.september'),
        i18n.t('periods.long.october'),
        i18n.t('periods.long.november'),
        i18n.t('periods.long.december'),
    ];

    function formatPeriod(period, short) {
        var startMonth = parseInt(moment(period.startDate).tz('UTC').format('M'));
        var endMonth = parseInt(moment(period.endDate).tz('UTC').format('M'));
        if (startMonth == endMonth) {
            return short ? monthNamesShort[startMonth - 1] : months[startMonth - 1] + ' ' + period.year;
        }
        else if (startMonth == 1 && endMonth == 12) {
            return period.year;
        }
        else if (startMonth == 1 && endMonth == 6) {
            return short ? i18n.t('periods.firstHalfYear') : i18n.t('periods.firstHalfYear') + ' ' + period.year;
        }
        else if (startMonth == 7 && endMonth == 12) {
            return short ? i18n.t('periods.secondHalfYear') : i18n.t('periods.secondHalfYear') + ' ' + period.year;
        }
        else if (startMonth == 1 && endMonth == 3) {
            return short ? i18n.t('periods.quarter1Short') : i18n.t('periods.quarter1') + ' ' + period.year;
        }
        else if (startMonth == 4 && endMonth == 6) {
            return short ? i18n.t('periods.quarter2Short') : i18n.t('periods.quarter2') + ' ' + period.year;
        }
        else if (startMonth == 7 && endMonth == 9) {
            return short ? i18n.t('periods.quarter3Short') : i18n.t('periods.quarter3') + ' ' + period.year;
        }
        else if (startMonth == 10 && endMonth == 12) {
            return short ? i18n.t('periods.quarter4Short') : i18n.t('periods.quarter4') + ' ' + period.year;
        }
        else {
            return short ? `${monthNamesShort[startMonth - 1]} - ${monthNamesShort[endMonth - 1]}` : `${months[startMonth - 1]} - ${months[endMonth - 1]}` + ' ' + period.year;
        }
    }

    Vue.filter('date', value => value == null ? '' : moment(value).tz(i18n.t('format.timezone')).format(i18n.t('format.date')));
    Vue.filter('daymonth', value => value == null ? '' : moment(value).tz(i18n.t('format.timezone')).format(i18n.t('format.daymonth')));
    Vue.filter('dateshort', value => value == null ? '' : moment(value).tz(i18n.t('format.timezone')).format(i18n.t('format.dateshort')));
    Vue.filter('dayanddate', value => value == null ? '' : moment(value).tz(i18n.t('format.timezone')).format(i18n.t('format.dayanddate')));
    Vue.filter('day', value => value == null ? '' : moment(value).tz(i18n.t('format.timezone')).format('dd'));
    Vue.filter('dateTime', value => value == null ? '' : moment(value).tz(i18n.t('format.timezone')).format(i18n.t('format.dateTime')));
    Vue.filter('periodShort', value => formatPeriod(value, true));
    Vue.filter('period', value => formatPeriod(value, false));
    Vue.filter('month', (month, year) => months[month] + ' ' + year);
    Vue.filter('time', value => value == null ? '' : moment(value).tz(i18n.t('format.timezone')).format(i18n.t('format.time')));
    Vue.filter('dayOfMonth', value => value == null ? '' : moment(value).format('D'));
    Vue.filter('commalist', value => value.split(',').join(', '));
    Vue.filter('nameLong', value => value == null || !value.lastName ? '' : `${value.firstName} ${value.middleName ? value.middleName + ' ' : ''}${value.lastName}`.trim());
    Vue.filter('nameShort', value => value == null || !value.lastName ? '' : `${value.initials} ${value.middleName ? value.middleName + ' ' : ''}${value.lastName} : ''}`.trim());
    Vue.filter('money', (value, currency) => !value && value !== 0 ? '-' : value.toLocaleString('nl-NL', { style: 'currency', currency: currency || 'EUR', useGrouping: true, maximumFractionDigits: 2, minimumFractionDigits: 2 }));
    Vue.filter('decimal', (value) => !value && value !== 0 ? '-' : value.toLocaleString('nl-NL', { style: 'decimal', useGrouping: true, maximumFractionDigits: 2, minimumFractionDigits: 2 }));
    Vue.filter('percentage', (value) => !value && value !== 0 ? '-' : (value * 100).toLocaleString('nl-NL', { style: 'decimal', useGrouping: true, maximumFractionDigits: 0, minimumFractionDigits: 0 }) + '%');
    Vue.filter('decimalshort', (value) => !value && value !== 0 ? '-' : value.toLocaleString('nl-NL', { style: 'decimal', useGrouping: true, maximumFractionDigits: 2, minimumFractionDigits: 0 }));
});