<template>
    <div v-if="totalPages > 1" class="bg-white d-flex align-items-center" :class="{'pagingfooter': !inmodal, 'justify-content-end':rightAlign,'mg-t-15':marginTop}">
        <slot/>
        <nav aria-label="Page navigation" :class="{'ml-auto':rightAlign}">
            <ul class="pagination pagination-basic mg-b-0">
                <template v-if="currentPage==0">
                    <li class="page-item">
                        <button  type="button" class="page-link" disabled>≪</button>
                    </li>
                    <li class="page-item">
                        <button  type="button" class="page-link" disabled>&lt;</button>
                    </li>
                </template>
                <template v-else>
                    <li class="page-item">
                        <button  type="button" class="page-link" @click="setCurrentPage(0)">≪</button>
                    </li>
                    <li class="page-item">
                        <button  type="button" class="page-link" @click="setCurrentPage(currentPage-1)">&lt;</button>
                    </li>
                </template>
                <li v-for="page in pages" :key="page.value" class="page-item" :class="{'active':page.value==currentPage}" id="1">
                    <button  type="button" v-if="page.value==currentPage" class="page-link">{{page.text}}</button>
                    <button  type="button" v-else class="page-link" @click="setCurrentPage(page.value)">{{page.text}}</button>
                </li>
                <template v-if="currentPage == totalPages-1">
                    <li class="page-item">
                        <button  type="button" class="page-link" disabled>&gt;</button>
                    </li>
                    <li class="page-item">
                        <button  type="button" class="page-link" disabled>≫</button>
                    </li>
                </template>
                <template v-else>
                    <li class="page-item">
                        <button  type="button" class="page-link" @click="setCurrentPage(currentPage+1)">&gt;</button>
                    </li>
                    <li class="page-item">
                        <button  type="button" class="page-link" @click="setCurrentPage(totalPages-1)">≫</button>
                    </li>
                </template>
            </ul>
        </nav>
    </div>
</template>

<script>
export default {
    name: 'Paging',
    props: {
        currentPage: Number,
        totalPages: Number,
        surroundingPageLinkSize: {
            type:Number,
            default: 3
        },
        inmodal: {
            type:Boolean,
            default: false
        },
        rightAlign: {
            type: Boolean,
            default: true
        },
        marginTop: {
            type: Boolean,
            default: true
        }
    },
    methods: {
        setCurrentPage(page) {
            this.$emit('page-changed', page);
        }
    },
    computed: {
        pages() {
            let buttons = [];
            let truncate = this.totalPages > (7 + (this.surroundingPageLinkSize * 2));
            if (!truncate) {
                for (let i = 0; i < this.totalPages; i++) {
                    buttons.push({value:i,text:i+1});
                }
            }
            else {
                var truncateEnd = this.currentPage < (1 + (this.surroundingPageLinkSize * 2)),
                    truncateBoth = (this.totalPages - (this.surroundingPageLinkSize * 2)) > this.currentPage && this.currentPage > (this.surroundingPageLinkSize * 2);
            
                if (truncateEnd) {
                    for (let j = 0; j < (4 + (this.surroundingPageLinkSize * 2)); j++) {
                        buttons.push({value:j,text:j+1});
                    }
                    buttons.push({value:null,text:'...'});
                    buttons.push({value:this.totalPages - 2,text:this.totalPages - 1});
                    buttons.push({value:this.totalPages - 1,text:this.totalPages });
                }
                else if (truncateBoth) {
                    buttons.push({value:0,text:'1'});
                    buttons.push({value:1,text:'2'});
                    buttons.push({value:null,text:'...'});

                    for (let k = (this.currentPage - this.surroundingPageLinkSize); k <= (this.currentPage + this.surroundingPageLinkSize); k++) {
                        buttons.push({value:k,text:k+1});
                    }
                    buttons.push({value:null,text:'...'});
                    buttons.push({value:this.totalPages - 2,text:this.totalPages - 1});
                    buttons.push({value:this.totalPages - 1,text:this.totalPages });
                }
                else { // Truncate start
                    buttons.push({value:0,text:'1'});
                    buttons.push({value:1,text:'2'});
                    buttons.push({value:null,text:'...'});
                    for (let l = this.totalPages - (2 + (this.surroundingPageLinkSize * 2)); l < this.totalPages; l++) {
                        buttons.push({value:l,text:l+1});
                    }
                }
            }
            return buttons;
        }
    }
};

</script>