import listmodule from './list.js';
import { CUSTOMERLIST_APPVERSION } from '../actions/customerlistactions.js';

const state = {
    appVersion: null,
    ...listmodule.state
};

const getters = {
    appVersion: state => state.appVersion,
    ...listmodule.getters
};

const actions = {
    [CUSTOMERLIST_APPVERSION]: ({ commit }, appVersion) => {
        commit(CUSTOMERLIST_APPVERSION, appVersion);
    },
    ...listmodule.actions
};

const mutations = {
    [CUSTOMERLIST_APPVERSION]: (state, appVersion) => {
        state.appVersion = appVersion;
        state.page = 0;
    },
    ...listmodule.mutations
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};