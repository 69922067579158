import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store';
import i18n from '../lang/i18n.js';
import { fetch } from '../lang/i18n.js';
Vue.use(VueRouter)
const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: []
})
fetch().then(() => {
    const ifNotAuthenticated = (to, from, next) => {
        if (!store.getters['auth/isAuthenticated']) {
            next();
            return;
        }
        next('/');
    };

    const ifAuthenticated = (to, from, next) => {
        if (store.getters['auth/isAuthenticated']) {
            next();
            return;
        }
        next('/login');
    };

    const routes = [
        {
            name: 'login',
            path: i18n.t('path.login'),
            component: () => import(/* webpackChunkName: "login" */ '../views/Login/Login.vue'),
            beforeEnter: ifNotAuthenticated,
            meta: { title: 'title.portal' }
        },
        {
            path: i18n.t('path.home'),
            name: 'home',
            component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue'),
            beforeEnter: ifAuthenticated
        },
        {
            name: 'forgotpassword',
            path: i18n.t('path.forgotpassword'),
            component: () => import(/* webpackChunkName: "login" */ '../views/Login/ForgotPassword.vue'),
            meta: { title: 'title.portal' },
            beforeEnter: ifNotAuthenticated,
        },
        {
            name: 'forgotpassword',
            path: i18n.t('path.setpassword'),
            component: () => import(/* webpackChunkName: "login" */ '../views/Login/setpassword.vue'),
            meta: { title: 'title.portal' },
            
        },
        {
            name: 'editprofile',
            path: i18n.t('path.editprofile'),
            component: () => import(/* webpackChunkName: "login" */ '../views/Login/EditProfile.vue'),
            meta: { title: 'title.portal' },
            beforeEnter: ifAuthenticated,
        },
        {
            name: 'users',
            path: i18n.t('path.users'),
            component: () => import(/* webpackChunkName: "settings" */ '../views/Settings/Users/UserList.vue'),
            meta: { title: 'title.administrator' },
            beforeEnter: ifAuthenticated,
        },
        {
            name: 'userEdit',
            path: i18n.t('path.users') + '/:id',
            component: () => import(/* webpackChunkName: "settings" */ '../views/Settings/Users/UserEdit.vue'),
            meta: { title: 'title.administrator' },
            beforeEnter: ifAuthenticated,
        },

        {
            path: i18n.t('path.customer'),
            component: () => import(/* webpackChunkName: "customer" */ '../views/Customers/Index.vue'),
            beforeEnter: ifAuthenticated,
            children: [
                {
                    name: 'customers',
                    path: '',
                    component: () => import(/* webpackChunkName: "customer" */ '../views/Customers/List.vue'),
                    meta: { title: 'title.customers' },
                },
                {
                    path: ':id',
                    component: () => import(/* webpackChunkName: "customer" */ '../views/Customers/CustomerEdit.vue'),
                    beforeEnter: ifAuthenticated,
                    children: [
                        {
                            path: '',
                            name: 'customersEdit',
                            component: () => import(/* webpackChunkName: "employee" */ '../views/Customers/Edit.vue'),
                            meta: { title: 'title.customers' },
                            props: true
                        },
                        {
                            path: i18n.t('path.customerusers'),
                            component: () => import(/* webpackChunkName: "knowledgechecks" */ '../views/Customers/User.vue'),
                            meta: { title: 'title.customerusers' },
                            props: true,
                            children: [
                                {
                                    name: 'customerUsers',
                                    path: '',
                                    component: () => import(/* webpackChunkName: "company" */ '../views/Customers/UserList.vue'),
                                    meta: { title: 'title.customerusers' },
                                    props: true
                                },
                                {
                                    name: 'customerUsersEdit',
                                    path: ':userId',
                                    component: () => import(/* webpackChunkName: "company" */ '../views/Customers/UserEdit.vue'),
                                    meta: { title: 'title.customerusers' },
                                    props: true
                                },
                            ],
                        }
                    ]


                }
            ]
        },

    ]

    router.afterEach((to) => {
        if (!to.meta || !to.meta.title) return;
        if (to.meta.count)
            document.title = i18n.tc(to.meta.title, to.meta.count)
        else
            document.title = i18n.t(to.meta.title);
    });
    router.addRoutes(routes);
});
export default router
